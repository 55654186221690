import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette, shadows }: Theme) => {
  return createStyles({
    stepCard: {
      transition: 'all 300ms ease-in',
      minHeight: spacing(34),
      '&:hover': {
        backgroundColor: '#3333330a',
        boxShadow: shadows[2],
      },
    },
    comingsoon: {
      borderRadius: spacing(0.25),
      backgroundColor: '#ecf0f1',
      padding: spacing(6, 2),
      margin: spacing(4, 0),
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& h4': {
        fontSize: '22pt',
        marginBottom: spacing(4),
      },
      '& a': {
        textDecoration: 'none',
      },
      '& button': {
        width: spacing(20),
      },
      '&:after': {
        pointerEvents: 'none',
        content: '" "',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
        border: `4px dashed ${palette.secondary.contrastText}`,
        position: 'absolute',
        top: 8,
        left: 8,
      },
    },
  });
});

export default useStyles;
