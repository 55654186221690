import * as React from 'react';
import Layout from '../layouts/Layout';

import { GrowthTrack } from '../components/NextSteps';

const NextSteps = () => {
  return (
    <Layout title="Next Steps">
      <div style={{ marginTop: 80 }} />
      <GrowthTrack />
    </Layout>
  );
};

export default NextSteps;
