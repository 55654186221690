import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import YoutubeVideo from '../../Shared/YoutubeVideo/YoutubeVideo';

import { useForms } from '../../../hooks/useForms';

import useStyles from './GrowthTrack.styles';
import { Link } from 'gatsby';

interface StepCard {
  title: string;
  url: string;
  subtitle: string;
  description: string;
}

const steps: StepCard[] = [
  {
    title: 'Step 1',
    subtitle: 'Become a Member',
    description:
      'Connect with Lifehouse Church and Discover who we are, learn details about beliefs, leadership and how the church is run, how we manage our funds, and how we intentionally organize our church. You will also have the opportunity to become a Lifehouse Member.',
    url: '/growth-track/step-1',
  },
  {
    title: 'Step 2',
    subtitle: 'Discover Your Design',
    description:
      'Find the details of your personality, discover your purpose in life and use your God-given gifts to make a difference in the lives of others.',
    url: '/growth-track/step-2',
  },
  {
    title: 'Step 3',
    subtitle: 'Join the Team',
    description:
      'Find out what it means to make a difference with Lifehouse Church, Sign up to the opportunities available at Lifehouse Church, To live out your purpose and serve others by using your God-given gifts.',
    url: '/growth-track/step-3',
  },
];

const StepCard = ({ title, subtitle, description, url }: StepCard) => {
  const classes = useStyles();

  let stepTitle = null;

  if (title === 'Step 1')
    stepTitle = (
      <Typography style={{ color: '#2d3436' }} align="center" variant="h5">
        step<b>ONE</b>
      </Typography>
    );
  else if (title === 'Step 2') {
    stepTitle = (
      <Typography style={{ color: '#2d3436' }} align="center" variant="h5">
        step<b>TWO</b>
      </Typography>
    );
  } else {
    stepTitle = (
      <Typography style={{ color: '#2d3436' }} align="center" variant="h5">
        step<b>THREE</b>
      </Typography>
    );
  }

  return (
    <Grid xs={12} sm={6} md={4} item>
      <Card className={classes.stepCard}>
        <Link style={{ textDecoration: 'none' }} to={url}>
          <CardContent>
            {stepTitle}
            <Typography
              style={{ color: '#2d3436' }}
              align="center"
              variant="subtitle1"
              gutterBottom
            >
              {subtitle}
            </Typography>
            <Typography
              style={{
                fontSize: '11pt',
                textAlign: 'justify',
                color: '#6f6f6f',
                margin: 16,
              }}
            >
              {description}
            </Typography>
          </CardContent>
        </Link>
      </Card>
    </Grid>
  );
};

const GrowthTrack = () => {
  const classes = useStyles();
  const theme = useTheme();

  const growthTrack = useForms('growth-track');

  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container>
      <YoutubeVideo videoUrl="https://www.youtube.com/embed/TFtXJLuruwI" />

      <div className={classes.comingsoon}>
        <Typography
          align="center"
          style={{ fontWeight: 500, marginBottom: !matches ? 16 : 16 }}
          variant={!matches ? 'h2' : 'h4'}
        >
          Growth Track
        </Typography>
        {growthTrack.map((gt) => (
          <span
            key={gt.date}
            style={{
              display: 'flex',
              minWidth: 256,
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid #ccc',
              padding: 8,
            }}
          >
            <Typography
              align="center"
              style={{
                margin: 0,
                //marginBottom: !matches ? 16 : 32,
                fontWeight: !matches ? 400 : 400,
              }}
              variant={!matches ? 'h4' : 'h6'}
            >
              {gt.date}
            </Typography>
            <a href={gt.form} target="_blank">
              <Typography style={{ textDecoration: 'underline' }}>
                {/* <Button color="secondary" variant="contained"> */}
                Sign Up
              </Typography>
              {/* </Button> */}
            </a>
          </span>
        ))}
      </div>
      <Grid justify="space-between" spacing={4} container>
        {steps.map((step) => (
          <StepCard key={step.title} {...step} />
        ))}
      </Grid>
    </Container>
  );
};

export default GrowthTrack;
