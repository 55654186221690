import React, { useRef } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import useStyles from './Alpha.styles';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const Alpha = (props: Props): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const { alpha } = useStaticQuery(graphql`
    {
      alpha: file(relativePath: { eq: "alpha-course.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
    }
  `);

  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container>
      <Card className={classes.root}>
        <a
          style={{ textDecoration: 'none' }}
          href="https://lifehousechurchsa.churchcenter.com/people/forms/292379"
          target="_blank"
        >
          <CardActionArea
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <GatsbyImage
              className={classes.imageWrapper}
              imgClassName={classes.image}
              image={alpha.childImageSharp.gatsbyImageData}
              alt="Join Alpha Course"
            />
            <CardContent>
              <Typography
                className={classes.title}
                gutterBottom
                variant="h4"
                component="h2"
              >
                Alpha Course
              </Typography>
              <Typography variant="h6" color="textSecondary" component="p">
                Sign Up Now, Starting Soon at a Cell Group near you...
              </Typography>
            </CardContent>
          </CardActionArea>
          {/* <div className={classes.imageWrapper}>
          <div className={classes.textWrapper}>
            <Typography
              className={classes.title}
              align="center"
              variant={!matches ? 'h2' : 'h4'}
            >
              Alpha Course
            </Typography>
            <Typography
              className={classes.subText}
              align="center"
              variant={!matches ? 'h4' : 'h6'}
            >
              Sign Up
            </Typography>
          </div>
          <GatsbyImage
            className={classes.overlay}
            imgClassName={classes.image}
            image={alpha.childImageSharp.gatsbyImageData}
            alt="Join Alpha Course"
          />
        </div> */}
        </a>
      </Card>
    </Container>
  );
};

export default Alpha;
