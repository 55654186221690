import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette }: Theme) => {
  return createStyles({
    imageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      maxWidth: 800, 
      margin: "auto",
      cursor: 'pointer',
    },
    image: {
      borderRadius: spacing(0.5),
    },
    overlay: {
      position: 'relative',
      '&:after': {
        top: 0,
        left: 0,
        content: '" "',
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: '#0000008F',
      }
    },
    textWrapper: {
      position: "absolute",
      zIndex: 1,
      height: "100%",
      width: "100%",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '&:after': {
        content: '" "',
        height: 'calc(100% - 48px)',
        width: 'calc(100% - 48px)',
        border: '12px solid #FFFFFF6F',
        top: 24,
        left: 24,
        zIndex: 1,
        pointerEvents: 'none',
        position: 'absolute'
      }
    },
    title: {
      fontWeight: 600,
      color: palette.common.white,
    },
    subText: {
      color: palette.common.white,
    }
  });
});

export default useStyles;
